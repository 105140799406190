import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header3 from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import Breadcrumb from "../elements/common/Breadcrumb";
import Helmet from "../component/common/Helmet";

class AmbientVisionsPrivacyPolicy extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <Fragment>
                <Helmet pageTitle="Ambient Visions™" />
                {/* Start Header Area  */}
                <Header3 homeLink="/" />
                {/* End Header Area  */}

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Privacy Policy'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div id="divAboutUs" className="rn-about-area ptb--60 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title text-left">
                                            <h3 className="fontWeight500">Privacy Policy</h3>
                                            <p>last updated: 2022/06/29</p>
                                            <p>Rapid Wildfire LLC built the Ambient Visions™ Alexa Skills as a Commercial product. This SERVICE is provided by Rapid Wildfire LLC and is intended for use as is.</p>
                                            <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</p>
                                            <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                                            <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Ambient Visions™ unless otherwise defined in this Privacy Policy.</p>    
                                            <h4 className="fontWeight500">Information Collection and Use</h4>
                                            <p>For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to none. The information that we request will be retained by us and used as described in this privacy policy.</p>
                                            <p>The Alexa Skills use third party services that may collect information used to identify you.</p>
                                            <p>Link to privacy policy of third party service providers used by the Alexa Skills:</p>
                                            <p><ul className="ft-link">
                                                <li><a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=468496">Amazon</a></li>
                                                <li><a href="https://policies.google.com/privacy?hl=en-US">Google</a></li>
                                            </ul></p>
                                            <h4 className="fontWeight500">Log Data</h4>
                                            <p>We want to inform you that whenever you use our Service, in a case of an error in the Alexa Skills we collect data and information (through third party products) called Log Data. This Log Data may include information such as the time and date of your use of the Service, and other statistics.</p>
                                            <h4 className="fontWeight500">Cookies</h4>
                                            <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers.</p>
                                            <p>Our Alexa Skills and dedicated websites do not use these “cookies” explicitly. However, we may use third party code and libraries that use “cookies” to collect information and improve their services.</p>
                                            <h4 className="fontWeight500">Service Providers</h4>
                                            <p>We may employ third-party companies and individuals due to the following reasons:</p>
                                            <p><ul className="ft-link">
                                                <li>To facilitate our Service;</li>
                                                <li>To provide the Service on our behalf;</li>
                                                <li>To perform Service-related services; or</li>
                                                <li>To assist us in analyzing how our Service is used.</li>
                                            </ul></p>
                                            <p>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
                                            <h4 className="fontWeight500">Security</h4>
                                            <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                                            <h4 className="fontWeight500">Children's Privacy</h4>
                                            <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
                                            <h4 className="fontWeight500">Changes to This Privacy Policy</h4>
                                            <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
                                            <h4 className="fontWeight500">Contact Us</h4>
                                            <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us using one of the contact details listed below.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Footer Area  */}
                <div id="divContact" className="ptb--80">
                    <Footer />
                    <a id="contact"></a>
                </div>
                
                {/* End Footer Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default AmbientVisionsPrivacyPolicy;