import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import Header3 from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import ModalVideo from 'react-modal-video';
import Helmet from "../component/common/Helmet";
import Slider from 'react-slick';

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Just ask',
        title: 'Ambient Visions™',
        description: 'Your best source for nature and relaxation videos, sounds, and music on Alexa. Just say: "Alexa, open Ambient Visions".',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
];

class AmbientVisionsHome extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false,
            video: null
        }
        this.openModal = this.openModal.bind(this);
        this.close = this.close.bind(this);
    }
    openModal(video) {
        this.setState({ isOpen: true, video: video });
    }
    close() {
        this.setState({ video: null });
    }
    render(){
        return(
            <Fragment>
                <Helmet pageTitle="Ambient Visions™" />
                {/* Start Header Area  */}
                <Header3 homeLink="/" />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center" key={index} data-black-overlay="4">
                            <div className="plr--40">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 col-md-8 col-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <img src={`/assets/images/av/alexa_open_ambient_visions3.png`} alt="Ask Alexa"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="video-background">
                                <img className="background-image" src={`/assets/images/av/beach.png`} alt="Ask Alexa"/>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>

                <div id="divAmbientVisions">
                    <div className="rn-counterup-area pt--50 pb--30 bg_color--1">
                        <div className="plr--40">
                            <div className="row">
                                <div className="section-title text-center">
                                    <p>Ambient Visions™ is an Alexa Skill that has thousands of hours of seamlessly looping ambient videos with nature sounds and soothing music. Best for sleep, focus, relaxation, and background entertainment. Just say: <i>"Alexa, open Ambient Visions"</i> and Ambient Visions™ will show up <b>on your Echo Show, Fire TV, Fire Tablet</b>, etc. No installation, no setup. Available for Alexa in US, UK, Canada and Germany.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="creative-portfolio-wrapper bg_color--1 pb--30">
                        <Slider {...{
                                infinite: true,
                                slidesToShow: 4,
                                arrows: true,
                                autoplay: true,
                                dots: true,
                                adaptiveHeight: true,
                                autoplaySpeed: 2000,
                                centerMode: true,
                                responsive: [
                                    {
                                        breakpoint: 1024,
                                        settings: {
                                          slidesToShow: 3,
                                        }
                                    },
                                    {
                                      breakpoint: 768,
                                      settings: {
                                        slidesToShow: 2,
                                      }
                                    }
                                  ]
                            }}>
                            <div className="plr--10"><img src={`/assets/images/portfolio/1.png`}/></div>
                            <div className="plr--10"><img src={`/assets/images/portfolio/2.png`}/></div>
                            <div className="plr--10"><img src={`/assets/images/portfolio/3.png`}/></div>
                            <div className="plr--10"><img src={`/assets/images/portfolio/4.png`}/></div>
                            <div className="plr--10"><img src={`/assets/images/portfolio/5.png`}/></div>
                            <div className="plr--10"><img src={`/assets/images/portfolio/6.png`}/></div>
                            <div className="plr--10"><img src={`/assets/images/portfolio/8.png`}/></div>
                            <div className="plr--10"><img src={`/assets/images/portfolio/7.png`}/></div>
                            <div className="plr--10"><img src={`/assets/images/portfolio/9.png`}/></div>
                            <div className="plr--10"><img src={`/assets/images/portfolio/10.png`}/></div>
                        </Slider>
                    </div>
                    {/* Start Portfolio Area  */}
                    {/*<div className="creative-portfolio-wrapper bg_color--1 pb--30">
                        <div className="container plr--30">
                            <div className="row row--5">
                                {PortfolioList.map((value , i ) => (
                                    <div className="col-4" key={i}>
                                        <div className="portfolio-style--3">
                                            <div className="thumbnail">
                                                <img className="w-100" src={`/assets/images/portfolio/${value.images}.png`}/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>*/}
                    {/* End Portfolio Area  */} 

                    {/* Start CounterUp Area */}
                    <div className="rn-counterup-area pt--35 pb--50 bg_color--1 line-separator">
                        <div className="plr--40">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <h3 className="fontWeight500">... and many more!</h3>
                                    </div>
                                </div>
                            </div>
                            <CounterOne />
                        </div>
                    </div>
                    {/* End CounterUp Area */}
                </div>

                <div id="divVideo" className="rn-counterup-area pt--35 bg_color--1">
                    <div className="plr--40">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="fontWeight500"><a id="video">Watch now!</a></h2>
                                    <p>See how <i>"Alexa, open Ambient Visions"</i> brings Sara into the fabulous world of Ambient Visions™. <br />Follow Sara and dive into an immersive experience right from your couch.</p>
                                </div>
                                {/* Start Video Area  */}
                                <div className="rn-section ptb--30 bg_color--1 line-separator">
                                    <div className="">
                                    <div className="row sercice-details-content align-items-center">
                                            <div className="col-4 pb--15 px-2">
                                             <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/av/yt/1.png" alt="Fireplace video"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.video==1} videoId='xSAqac_46Dk' onClose={this.close} />
                                                    <button className="video-popup2 position-top-center" onClick={()=> this.openModal(1)}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                            <div className="col-4 pb--15 px-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/av/yt/2.png" alt="Colorful fish video"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.video==2} videoId='ez6KPNmXaZk' onClose={this.close} />
                                                    <button className="video-popup2 position-top-center" onClick={()=> this.openModal(2)}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                            <div className="col-4 pb--15 px-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/av/yt/3.png" alt="Thunderstorm video"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.video==3} videoId='tCWBbZ04M3w' onClose={this.close} />
                                                    <button className="video-popup2 position-top-center" onClick={()=> this.openModal(3)}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-12 px-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/av/sara1.jpg" alt="Follow Sara into the fabuluous world of Ambient Visions™"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.video==0} videoId='2x0mQDDBwrg' onClose={this.close} />
                                                    <button className="video-popup2 position-top-center" onClick={()=> this.openModal(0)}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-4 pt--15 px-2">
                                             <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/av/yt/4.png" alt="Ocean video"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.video==4} videoId='HxbYtwgWfaY' onClose={this.close} />
                                                    <button className="video-popup2 position-top-center" onClick={()=> this.openModal(4)}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                            <div className="col-4 pt--15 px-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/av/yt/5.png" alt="Fish tank video"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.video==5} videoId='mwB6s2eZYDg' onClose={this.close} />
                                                    <button className="video-popup2 position-top-center" onClick={()=> this.openModal(5)}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                            <div className="col-4 pt--15 px-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/av/yt/6.png" alt="Wildlife video"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.video==6} videoId='Mt9JIuMwRuA' onClose={this.close} />
                                                    <button className="video-popup2 position-top-center" onClick={()=> this.openModal(6)}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Video Area  */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start About Area  */}
                <div id="divAboutUs" className="rn-about-area ptb--20 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="plr--40">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title text-center">
                                            <h2 className="fontWeight500"><a id="about">About</a></h2>
                                            <p>Ambient Visions™ is an Alexa Skill created in late 2017 when Amazons very first Echo Show went on sale. Formerly known as "Ambient Visuals" we created a series of Alexa Skills to provide ambient videos on Alexa-enabled screen devices. Since then, Ambient Visions™ has become the most comprehensive offering for nature and relaxation videos on Alexa. It's used by tens of thousands of individuals around the globe for relaxation, focus, sleep and in-home entertainment.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                <div id="divVideo" className="rn-counterup-area bg_color--1">
                    <div className="plr--40">
                        <div className="row">
                            <div className="col-12">
                                {/* Start Video Area  */}
                                <div className="rn-section bg_color--1">
                                    <div className="">
                                    <div className="row sercice-details-content align-items-center">
                                            <div className="col-4 px-2">
                                             <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/av/yt/7.png" alt="Campfire video"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.video==7} videoId='QgW2xs20kaY' onClose={this.close} />
                                                    <button className="video-popup2 position-top-center" onClick={()=> this.openModal(7)}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                            <div className="col-4 px-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/av/yt/8.png" alt="Norway video"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.video==8} videoId='BjYYRHgrR_0' onClose={this.close} />
                                                    <button className="video-popup2 position-top-center" onClick={()=> this.openModal(8)}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                            <div className="col-4 px-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/av/yt/9.png" alt="Zen Garden video"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.video==9} videoId='kcAA6b1e8MM' onClose={this.close} />
                                                    <button className="video-popup2 position-top-center" onClick={()=> this.openModal(9)}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Video Area  */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start About Area  */}
                <div id="divAboutUs" className="rn-about-area bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="plr--40">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="row mt--20 text-center">
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="single-column">
                                                    <h4 className="tilte">Who we are</h4>
                                                    <p>Ambient Visions™ is an Alexa Skill created by Rapid Wildfire LLC. Based out of Houston, TX our small team is ambitiously working on Ambient Visions™ since late 2017. We're driven by our passion for voice technology, and using it in ways it improves people's life.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 mt_sm--30">
                                                <div className="single-column">
                                                    <h4 className="tilte">What we do</h4>
                                                    <p>We're creating voice experiences with strong focus on visual. We do code, design, cut, edit, and compose for Ambient Visions™ and source and license video and audio content from various platforms like Pond5, Soundstripe, and Envato.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                <div id="divVideo" className="rn-counterup-area pt--35 bg_color--1">
                    <div className="plr--40">
                        <div className="row">
                            <div className="col-12">
                                {/* Start Video Area  */}
                                <div className="rn-section bg_color--1">
                                    <div className="">
                                    <div className="row sercice-details-content align-items-center">
                                            <div className="col-4 pb--15 px-2">
                                             <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/av/yt/10.png" alt="Sunrise video"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.video==10} videoId='svOBlwfaZNA' onClose={this.close} />
                                                    <button className="video-popup2 position-top-center" onClick={()=> this.openModal(10)}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                            <div className="col-4 pb--15 px-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/av/yt/11.png" alt="Mountain river video"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.video==11} videoId='KWoVY4YdwLU' onClose={this.close} />
                                                    <button className="video-popup2 position-top-center" onClick={()=> this.openModal(11)}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                            <div className="col-4 pb--15 px-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/av/yt/12.png" alt="Focus video"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.video==12} videoId='dusPR_InACM' onClose={this.close} />
                                                    <button className="video-popup2 position-top-center" onClick={()=> this.openModal(12)}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Video Area  */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Footer Area  */}
                <div id="divContact" className="ptb--80">
                    <Footer />
                    <a id="contact"></a>
                </div>
                
                {/* End Footer Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default AmbientVisionsHome;