import React, { Component } from "react";
import { FaFacebookF, FaTwitter ,FaInstagram , FaYoutube, FaPinterest } from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/AmbientVisions.tv'},
    //{Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/ambientvisions'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/ambient_visions'},
    {Social: <FaYoutube /> , link: 'https://www.youtube.com/channel/UC2rTUYo_6VXy1opKuh6FeFA'},
    {Social: <FaPinterest /> , link: 'https://www.pinterest.com/ambient_visions'}
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>FEEDBACK?</span>
                                        <a className="rn-button-style--2" href="mailto:team@rapidwildfire.com">
                                            <span>Contact Us</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Quick Links</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/privacy">Privacy Policy</a></li>
                                                    <li><a href="https://www.amazon.com/product-reviews/B07QS82SXW/ref=cm_cr_arp_d_viewopt_sr?ie=UTF8&filterByStar=positive&reviewerType=all_reviews&sortBy=recent&pageNumber=1#reviews-filter-bar" target="__blank">Testimonials</a></li>
                                                    <li><a href="https://alexa-skills.amazon.com/apis/custom/skills/amzn1.ask.skill.b04ff805-8b00-49a9-aa61-e774ee05cd1a/launch" target="__blank">Launch Skill</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Say Hello</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:team@rapidwildfire.com">team@rapidwildfire.com</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`} target='__blank'>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2022 Rapid Wildfire LLC. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;