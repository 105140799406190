import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || Relaxing videos on Amazon Alexa</title>
                    <meta name="description" content="Ambient Visions™ is your best source for nature and relaxation videos, sounds, and music on Alexa. Best on Echo Show, Fire TV and Fire Tablet, this skill offers hundreds of seamlessly looping videos for sleep, focus, relaxation and light entertainment." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
